<template>
  <div class="progress-wraper">
    <div class="progress-pointers">
      <div
        class="progress-pointer"
        v-for="(segment, index) in segments"
        :key="'progress-pointer-' + index"
        :class="{ inactive: points < segment.points }"
        :style="{ left: 100 * ((index + 1) / segments.length) + '%' }"
      >
        <div class="outer-circle">
          <div class="inner-circle">
            {{ segment.name }}
            <!-- <span class="euro-symbol">€</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="progress-dividers">
      <div
        class="progress-divider"
        v-for="(segment, index) in segments"
        :key="'progress-divider-' + index"
        :class="{ inactive: points < segment.points }"
        :style="{ left: 100 * ((index + 1) / segments.length) + '%' }"
      >
        <div></div>
      </div>
    </div>
    <div class="progress-container">
      <div class="progress-bar" :style="{ width: percentage + '%' }"></div>
    </div>
    <div class="progress-values mb-12">
      <div
        v-for="(segment, index) in segments"
        :key="'progress-value-' + index"
        :class="{ inactive: points < segment.points }"
        :style="{ left: 100 * ((index + 1) / segments.length) + '%' }"
      >
        {{ segment.points }} punti
      </div>
    </div>
    <div v-if="nextDiscount">
      Ti mancano <strong>{{ pointsTillDiscount }} punti</strong> per risparmiare
      <strong>{{ nextDiscount }}</strong>
      sulla tua spesa.
    </div>
  </div>
</template>
<style lang="scss">
.progress-wraper {
  //padding: 10px 10px;
  margin-right: 23px;
  .progress-container {
    width: 100%;
    height: 20px;
    background-color: #f1f1f1;
    border-radius: 15px;
    position: relative;
    margin-top: 60px;
    margin-right: 13px;
    overflow: hidden;
  }

  .progress-bar {
    height: 100%;
    background-color: #f0a3a3;
    background-image: linear-gradient(to right, $secondary, #f0a3a3);
    border-radius: 15px;
    position: absolute;
    display: flex;
    border-radius: 15px;
    max-width: 100%;
  }
  .progress-bar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 20px 20px;
    background-repeat: repeat;
    overflow: hidden;
  }
  .progress-pointer {
    top: -56px;
    transform: translateX(-50%);
    position: absolute;
    .outer-circle {
      width: 40px;
      height: 40px;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      position: relative;
    }
    .inner-circle {
      font-size: 16px;
      font-weight: 600;
      width: 33px;
      height: 33px;
      background-color: $secondary;
      color: white;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      right: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      .euro-symbol {
        font-weight: normal;
        font-size: 12px;
        padding-top: 3px;
      }
    }
  }
  .progress-pointer.inactive {
    .outer-circle {
      background-color: var(--v-grey-darken1);
    }
    .inner-circle {
      background-color: var(--v-grey-lighten2);
      color: var(--v-grey-darken1);
    }
  }
  .progress-pointer:before {
    position: absolute;
    left: 28px;
    bottom: 28px;
    content: "";
    width: 15px;
    height: 11px;
    background-image: url(/logo/foglia_big.png);
    background-size: cover;
    z-index: 2;
  }
  .progress-pointer.inactive::before {
    filter: grayscale(1);
  }
  .progress-pointer:after {
    left: 20px;
    transform: translateX(-50%);
    content: "";
    position: absolute;
    margin-top: 3px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid $primary;
    z-index: 2;
  }

  .progress-pointer.inactive::after {
    border-top-color: var(--v-grey-darken1);
  }

  .progress-divider {
    position: absolute;
    transform: translate(-50%, -3px);
    width: 26px;
    height: 26px;
    background-color: white;
    z-index: 2;
    border-radius: 50%;
    border: 2px solid $secondary;
    padding: 4px;
  }
  .progress-divider.inactive {
    border-color: var(--v-grey-darken1);
  }
  .progress-divider > div {
    background-color: $secondary;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .progress-divider.inactive > div {
    background-color: var(--v-grey-darken1);
  }
  .progress-values,
  .progress-dividers,
  .progress-pointers {
    position: relative;
    width: 100%;
  }
  .progress-values {
    margin-top: 8px;
  }
  .progress-values > div {
    position: absolute;
    width: min-content;
    text-align: center;
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 12px;
  }
  .progress-values > div.inactive {
    opacity: 0.7;
  }
}
</style>
<script>
export default {
  name: "PointsProgressBar",
  props: {
    points: { type: Number, required: true },
    segments: { type: Array, required: true }
  },
  data() {
    return {
      nextDiscount: null,
      pointsTillDiscount: null
    };
  },
  computed: {
    percentage() {
      let lowerBound;
      let upperBound;
      let segmentNumber;
      let segmentSize = (100 / this.segments.length).toFixed(2);
      if (this.segments[this.segments.length - 1].points <= this.points) {
        return 100;
      }
      for (let i = 0; i < this.segments.length; i++) {
        if (this.segments[i].points > this.points) {
          lowerBound = i == 0 ? 0 : this.segments[i - 1].points;
          upperBound = this.segments[i].points;
          segmentNumber = i;
          this.saveNext(this.segments[i]);
          break;
        }
      }
      return (
        segmentNumber * segmentSize +
        ((this.points - lowerBound) / (upperBound - lowerBound)) * segmentSize
      ).toFixed(2);
    }
  },
  methods: {
    saveNext(data) {
      this.nextDiscount = data.name;
      this.pointsTillDiscount = data.points - this.points;
    }
  }
};
</script>
